<template>
  <div>
    <div style="font-size: 15px">
      <div v-if="total1 == 0">此评论暂无回复 !</div>
      <div v-else>
        <div>
          <!-- 父评论显示 parentId-->
          <el-row
            style="
              margin-top: 20px;
              word-break: break-word;
              margin-bottom: 10px;
            "
          >
            <div style="float: left; width: 80%">
              <div style="display: flex">
                <el-avatar shape="square" :src="parentObeject.userImageUrl">
                </el-avatar>
                <div style="width: 85%; text-align: left; margin-left: 20px">
                  <div>
                    <span style="float: left; font-weight: bold"
                      >{{ parentObeject.userName
                      }}<span style="color: grey; font-size: 10px">
                        {{ parentObeject.createTime }}</span
                      ></span
                    >
                    <br />
                    <div>{{ parentObeject.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-row>
          <el-row>
            <div style="margin-left: 40px; margin-top: 10px; width: 90%">
              <div v-for="(item, index1) in CommentForm" :key="index1">
                <div
                  style="
                    display: flex;
                    margin-top: 10px;
                    word-break: break-word;
                  "
                >
                  <el-avatar shape="square" :src="item.userImageUrl">
                  </el-avatar>
                  <div
                    style="
                      width: 75%;
                      text-align: left;
                      margin-left: 20px;
                      margin-bottom: 20px;
                    "
                  >
                    <b style="float: left">
                      {{ item.userName
                      }}<span style="color: grey; font-size: 10px"> 回复 </span
                      >{{ item.parentUserName }}
                      <span style="color: grey; font-size: 10px">{{
                        item.createTime
                      }}</span>
                    </b>
                    <br />
                    <div style="float: left">{{ item.content }}</div>
                  </div>
                  <div style="float: right; width: 30%">
                    <el-link
                      :underline="false"
                      icon="el-icon-delete"
                      type="info"
                      @click="DeleteComment(item)"
                    >
                      删除
                    </el-link>
                  </div>
                </div>
              </div>
            </div>
          </el-row>
          <el-row>
            <div v-if="querypage1.pageSize < total1">
              <el-link
                :underline="false"
                @click="AllComment"
                style="width: 100%"
                >展开</el-link
              >
            </div>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChildernCommentList, UpdateComment } from '@/api/cloudmarket/commentInfo'
import { deleteDialog } from '@/libs/confirmDialog'

export default {
  props: {
    CommentObejctId: {
      type: [String, Number]
    },
    parentObeject: {
      type: Object
    }
  },
  data () {
    return {
      CommentForm: [],
      querypage1: {
        pageSize: 10
      },
      total1: 0,
      showMore: 0,

      wordnum1: 0,
      Splaceholder: '',
      CommentContent: ''
    }
  },
  mounted () {
    this.CommentedList()
  },
  methods: {
    // 已有的评论列表
    async CommentedList () {
      const res = await getChildernCommentList(this.querypage1.pageSize, { parentId: this.CommentObejctId })
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.CommentForm = res.data.data.commentDTOList
      this.total1 = res.data.data.total
      // console.log('9999999999',this.CommentForm)
    },

    AllComment () {
      // 每次点击多显示10条
      this.showMore = 1
      this.querypage1.pageSize = this.querypage1.pageSize + 10
      this.CommentedList()
    },

    async DeleteComment (para) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await UpdateComment(para.id)
        if (res.status !== 200) return this.$message.error('操作失败')
        this.$message.success('评论删除成功')
        this.CommentedList()
      }
    }
  }
}
</script>

