import axios from '@/libs/api.request'

// 删除、恢复评论
export const UpdateComment = (id) => {
  let uri = '/objectComment/update/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}
// 评论列表
export const getCommentList = (pageNum, pageSize, quiese) => {
  let uri = '/objectComment/commentList/page/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese,

  })
}

export const getChildernCommentList = (pageSize, quiese) => {
  let uri = '/objectComment/commentList/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}


// 新增黑名单
export const insertBlack = (userid, cause) => {
  let uri = '/objectComment/blackList/insert/' + userid
  return axios.request({
    url: uri,
    method: 'get',
    params: { cause: cause }
  })
}


export const getBlackList = (pageNum, pageSize, userId) => {
  let uri = '/objectComment/blackList/queryBlackList/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get',
    params: { userId: userId }
  })
}

// 移出黑名单
export const updateBlack = (id) => {
  let uri = '/objectComment/blackList/update/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}