<template>
  <el-select
    :clearable="Sclearable"
    @clear="$emit('clear', $event)"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :placeholder="Splaceholder"
    style="width: 100%"
    :multiple="Smultiple"
    filterable
    :collapse-tags="collapseTag"
    :disabled="disabledFlag"
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="
        optionKey.label2 === ''
          ? item[optionKey.label]
          : item[optionKey.label] + '_' + item[optionKey.label2]
      "
      :value="
        optionKey.value === 'multiValue'
          ? item.id + ',' + item.name
          : item[optionKey.value]
      "
      :disabled="item[optionKey.disabled]"
    >
      <span>{{
        optionKey.label2 === ""
          ? item[optionKey.label]
          : item[optionKey.label] + "_" + item[optionKey.label2]
      }}</span>
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    optionKey: {
      type: Object,
      required: true
    },
    Splaceholder: {
      type: String,
      default: '请选择'
    },
    // 绑定value是为了外面也可以传值改变到里面的值双向绑定
    value: {
      type: [String, Object, Boolean, Number, Array]
    },
    Smultiple: {
      type: Boolean,
      default: false
    },
    Sclearable: {
      type: Boolean,
      default: true
    },
    disabledFlag: {
      type: Boolean,
      default: false
    },
    collapseTag: {
      type: Boolean,
      default: false
    }
  }
}
</script>
