import ElementUI from 'element-ui'

export const deleteDialog = async (title, that) => {
  const confirmResult = await ElementUI.MessageBox.confirm(title, '提示信息', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).catch(err => err)
  if (confirmResult !== 'confirm') {
    return that.$message.info('操作已取消')
  }
  return confirmResult
}


export const disableDia = async (title, that) => {
  const confirmResult = await ElementUI.MessageBox.prompt(title, '提示信息', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(({ value }) => {
    return value
  }).catch(() => {
    that.$message.info('操作已取消')
  });
  return confirmResult
}
