<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>咨询评论管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户评论</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="querytype">
            <div style="display: inline-block" class="divSpan">资源类型：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.querytype"
                :options="resourceNameOptions"
                :optionKey="ResourceOptionKeys"
                Splaceholder="请选择"
                @clear="getResourceList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="queryname">
            <div style="display: inline-block" class="divSpan">资源名称：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.queryname"
                clearable
                @clear="getResourceList"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="queryusername">
            <div style="display: inline-block" class="divSpan">用户名：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.queryusername"
                clearable
                @clear="getResourceList"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="queryuserID">
            <div style="display: inline-block" class="divSpan">用户ID：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.queryuserID"
                clearable
                @clear="getResourceList"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ResourceList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="name"
            label="资源名称"
            min-width="200px"
          ></el-table-column>

          <el-table-column label="资源类型" min-width="100px" prop="type">
          </el-table-column>
          <el-table-column
            prop="userName"
            label="用户名"
            min-width="100px"
          ></el-table-column>

          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="content"
            label="发起评论内容"
            min-width="200px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="最新评论/回复时间"
            min-width="150px"
          ></el-table-column>

          <el-table-column label="操作" min-width="200px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="viewComment(scope.row)"
                >查看/回复</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deleteComment(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 评论 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="CommentDialogVisible"
      width="40%"
      @close="CommentDialogClosed"
    >
      <CommentComponent
        :CommentObejctId="commentId"
        :parentObeject="parentObeject"
        v-if="CommentDialogVisible"
      ></CommentComponent>
    </el-dialog>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'
import { getCommentList, UpdateComment } from '@/api/cloudmarket/commentInfo'
import { deleteDialog } from '@/libs/confirmDialog'
import CommentComponent from '@/components/myself/comment'

export default {
  components: { ELselect, CommentComponent },
  data () {
    return {
      queryinfo: {
        queryname: '',
        querytype: '',
        queryusername: '',
        queryuserID: '',
        pageNum: 1,
        pageSize: 8
      },
      total: 0,
      ResourceList: [],
      ResourceOptionKeys: {
        value: 'type',
        label: 'name',
        label2: ''
      },
      resourceNameOptions: [
        {
          name: '全部',
          type: ''
        },
        {
          name: '模板',
          type: 'template'
        },
        {
          name: '组件',
          type: 'component'
        },
        {
          name: '素材',
          type: 'material'
        },
      ],
      CommentDialogVisible: false,
      commentId: '',
      parentObeject: {}
    }
  },
  mounted () {
    this.getResourceList()
  },
  methods: {
    async getResourceList () {
      let quiese = { name: this.queryinfo.queryname, type: this.queryinfo.querytype, userName: this.queryinfo.queryusername, userId: this.queryinfo.queryuserID }
      const res = await getCommentList(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ResourceList = res.data.data.list
      this.total = res.data.data.total
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getResourceList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getResourceList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getResourceList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getResourceList()
    },
    // 查看评论按钮
    viewComment (item) {
      this.CommentDialogVisible = true
      this.commentId = item.id
      this.parentObeject = item
      // window.sessionStorage.setItem('CommentId', item.id)
    },
    CommentDialogClosed () {
      this.CommentDialogVisible = false
    },


    async deleteComment (item) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await UpdateComment(item.id)
        if (res.status !== 200) return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getResourceList()
      }

    },
  }

}
</script>
<style lang="less" scoped>
/deep/ .el-form-item__content {
  margin-right: 20px;
}
</style>